<template>
  <v-layout>
    <v-main @click="rail = true">
      <img src="logo-white.png" width="200px"  style="position: absolute;  bottom:1rem ; z-index:999;right:1rem; "></img>
      <router-view />
    </v-main>
  </v-layout>
</template>

<script>
export default {
  data: () => ({
    tab: null,
  }),
  components: {},
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  border: none;
  outline: none;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}

.v-main {
  height: 100vh;
  max-height: 100vh !important;
  color: black;
  background-color: #1d1c1c !important;
}

.typewriter h2 {
  overflow: hidden;
  border-right: 0.15em solid white;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.1em;
  animation: typing 3.5s steps(40, end), blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
}

@media (max-width: 900px) {
  h1 {
    font-size: 20px;
  }
}
</style>
