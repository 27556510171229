<template>
  <v-card id="body" class="mx-auto" variant="text">
    <v-card-title class="heartbeat mt-4" id="title" style="text-align: center">
      Entre em contato
      <p id="me">Comigo!</p>
    </v-card-title>
    <v-card variant="text" style="width: 70%">
      <v-form ref="form" v-model="isValid">
        <v-text-field
          v-model="phone"
          color="deep-purple"
          label="Nome"
          variant="filled"
        ></v-text-field>
        <v-text-field
          v-model="email"
          :rules="[rules.email]"
          color="deep-purple"
          label="Email "
          type="email"
          variant="filled"
        ></v-text-field>
        <v-textarea
          v-model="message"
          color="deep-purple"
          label="Mensagem"
          rows="3"
          variant="filled"
          auto-grow
        ></v-textarea>
      </v-form>

      <v-card-actions>
        <v-btn variant="text" class="bg-deep-purple" @click="reset()">
          Limpar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!isValid"
          :loading="isLoading"
          class="bg-deep-purple"
          color="white"
        >
          Enviar
        </v-btn>
      </v-card-actions></v-card
    >
  </v-card>
</template>

<script>
export default {
  data: () => ({
    message: null,
    name: null,
    email: null,
    isValid: false,
    isLoading: false,
    rules: {
      email: (v) => !!(v || "").match(/@/) || "Please enter a valid email",
      length: (len) => (v) =>
        (v || "").length >= len || `Invalid character length, required ${len}`,

      required: (v) => !!v || "This field is required",
    },
  }),
  methods: {
    reset() {
      this.name = null;
      this.email = null;
      this.message = null;
    },
  },
};
</script>
<style scoped>
#body {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  overflow-y: auto;
  flex-direction: column;
}

#me {
  color: #9558ff;
  font-size: 45px;
  font-weight: 700;
}

#title {
  font-size: 40px;
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both !important;
  animation: heartbeat 1.5s ease-in-out infinite both !important;
}
@media (max-width: 900px) {
  #body {
    margin-top: 1rem;
    max-width: 80vw;
  }
  #title {
    font-size: 30px;
  }
  #me {
    font-size: 35px;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2024-6-6 23:4:47
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
::-webkit-scrollbar {
  display: block;
  background-color: rgba(0, 0, 0, 0);
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-button:hover {
  display: none;
}

::-webkit-scrollbar-thumb {
  width: 6px;
  background-color: #673ab7;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #673ab7;
}

::-webkit-scrollbar-thumb:active {
  background-color: #673ab7;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-track:hover {
  background-color: rgba(0,  0,  0,  0);
}
</style>
