import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/Home.vue";
import politicas from "@/views/Email.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
   {
    path: "/politicas",
    name: "politicas",
    component: politicas,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
