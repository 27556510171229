<template>
  <div class="main mt-10">
    <div>Developer</div>
    <div class="words">
      <span :class="currentClass">Thierry Severo</span>
      <span :class="currentClassUp">Full Stack </span>
    </div>
  </div>
  <v-card variant="text" class="content">
    <v-hover v-slot="{ props }">
      <v-card variant="text" class="col img" v-bind="props">
        <v-img
          :width="475"
          src="https://cdn.vuetifyjs.com/images/parallax/material.jpg"
          aspect-ratio="1/1"
          conver
        >
        </v-img>
      </v-card>
    </v-hover>
    <v-card variant="text" class="col icons"
      ><span>
        <v-btn
          class="mb-1 mr-2"
          icon="mdi-whatsapp"
          color="green"
          href="https://wa.me/555591940223"
          target="_blank"
        />
        Whatsapp</span
      >
      <span>
        <v-btn
          class="mb-1 mr-2"
          icon="mdi-linkedin"
          color="blue"
          href="https://www.linkedin.com/in/thierrysevero/"
          target="_blank"
        />
        Linkedin</span
      >
      <span>
        <v-btn
          class="mb-1 mr-2"
          icon="mdi-github"
          color="black"
          href="https://github.com/gitsevero"
          target="_blank"
        />
        Github</span
      >

      <span>
        <v-btn
          class="mb-1 mr-2"
          icon="mdi-badge-account-horizontal-outline"
          color="#f0f0f0"
          href="https://github.com/gitsevero"
          target="_blank"
        />
        Curriculo</span
      ><span>
        <v-btn
          class="mb-1 mr-2"
          icon="mdi-email"
          color="#f0f0f0"
          target="_blank"
        />
        job@thierrysevero.com
        <v-btn
          elevation="0"
          id="copy"
          color="transparent"
          class="mb-1"
          icon="mdi-content-copy"
          @click="copyToClipboard"
        />
      </span>
    </v-card>
  </v-card>
<footer>
<a href="/politicas">Privacidade</a>
</footer>
</template>

<script>
export default {
  methods: {
    copyToClipboard() {
      const textToCopy = "job@thierrysevero.com";

      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          console.log("Texto copiado com sucesso!");
        })
        .catch((err) => {
          console.error("Erro ao copiar o texto: ", err);
        });
    },
  },
  data() {
    return {
      currentClassIndexUp: 0,
      currentClassIndex: 1,
      classes: ["slide-out-top", "slide-in-bottom"],
      intervalId: null,
    };
  },
  computed: {
    currentClass() {
      return this.classes[this.currentClassIndex];
    },
    currentClassUp() {
      return this.classes[this.currentClassIndexUp];
    },
  },
  mounted() {
    this.intervalId = setInterval(this.changeClassUp, 4000);
    this.intervalId = setInterval(this.changeClass, 4000);
  },
  methods: {
    changeClass() {
      this.currentClassIndex =
        (this.currentClassIndex + 1) % this.classes.length;
    },
    changeClassUp() {
      this.currentClassIndexUp =
        (this.currentClassIndexUp + 1) % this.classes.length;
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
};
</script>

<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}
.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-height: 100%;
  padding-bottom: 1rem;
  overflow-y: auto !important;
}
.col {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.icons {
  width: 45%;
  padding-left: 1rem;
}

.img {
  width: 55%;
  align-items: flex-end !important;
}
#copy:hover {
  pointer-events: painted;
}
h1 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 900px) {
  .content {
    width: 90%;
    margin: auto;
  }
  h1 {
    margin-top: 0;
    margin-bottom: 0.3rem;
  }
  .img {
    align-items: center !important;
  }
  .col {
    width: 100% !important;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-left: 0;
    margin-top: 0.5rem;
  }

  .main {
    margin-top: 0 !important;
    width: 100% !important;
    font-size: 25px !important;
  }

  .words {
    margin-right: 35px;
  }
}
::-webkit-scrollbar {
  display: block;
  background-color: rgba(0, 0, 0, 0);
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-button:hover {
  display: none;
}

::-webkit-scrollbar-thumb {
  width: 6px;
  background-color: #673ab7;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #673ab7;
}

::-webkit-scrollbar-thumb:active {
  background-color: #673ab7;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-track:hover {
  background-color: rgba(0,  0,  0,  0);
}
</style>
<style media="screen" scoped>
.main {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 40px;
}

.main div {
  min-height: 100px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.words {
  display: flex;
  justify-content: flex-start !important;
  margin-left: 5px;
}
.words span {
  color: #9152ff !important;
  font-weight: 600;
  position: absolute;
}

.slide-out-top {
  color: white;
  -webkit-animation: slide-out-top 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1s
    both;
  animation: slide-out-top 0.4s cubic-bezier(0.55, 0.085, 0.68, 0.53) 1s both;
}

.slide-in-bottom {
  color: white;
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    1s both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 1s both;
}

/* ----------------------------------------------
* Generated by Animista on 2024-6-9 22:14:53
* Licensed under FreeBSD License.
* See http://animista.net/license for more info. 
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
* ----------------------------------------
* animation slide-out-top
* ----------------------------------------
*/
@-webkit-keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
}
@keyframes slide-out-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(-100px);
    transform: translateY(-100px);
    opacity: 0;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2024-6-9 22:16:20
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
</style>
